import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { HttpSupportService } from './http-support.service';
import { BonInterface } from '../interfaces/bon.interface';
import { catchError, map, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { BonDetailInterface } from '../interfaces/bon-detail.interface';

@Injectable({
  providedIn: 'root'
})
export class ServiceTicketsService
{
  public constructor(private http: HttpClient,
                     private httpSupport: HttpSupportService) {
  }

  getListByUserId(userId: number): Observable<Array<BonInterface>> {
    const actionUrl = this.httpSupport.getApiUrl('/EmployeeServicecall/') + userId;

    return this.http.get<{ Success: boolean, Bonnen: Array<BonInterface>}>(actionUrl)
      .pipe(
        map(result => result.Bonnen),
        catchError( (err, caught) => {
          return of([]);
        })
      );
  }

  getTravelById(ticketId: string): Observable<BonDetailInterface> {
    const actionUrl = this.httpSupport.getApiUrl('/servicetravel/') + ticketId;

    return this.http.get<{ Success: boolean, Record: BonDetailInterface }>(actionUrl)
      .pipe(
        map(result => result.Record),
        catchError((err, caught) => {
          return of(null);
        })
      );
  }

  updateTravel(travelTicket: BonDetailInterface): Observable<any> {
    const actionUrl = this.httpSupport.getApiUrl('/servicetravel') + '/' + travelTicket.MEDEWERKERID.toString() + '*'
      + travelTicket.CODE + '*' + travelTicket.VOLGNR.toString();

    return this.http.post<any>(actionUrl, travelTicket);
  }

}
