import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.css']
})
export class AuthorizationComponent implements OnInit, OnDestroy {

  private _destroyed$ = new Subject<void>();

  email: string;
  password: string;

  returnUrl: string;

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    this.route.params
      .pipe(takeUntil(this._destroyed$))
      .subscribe(params => {
        if (params.action === 'logout') {
          this.authService.logout();
        }
      });

    this.route.queryParams
      .pipe(takeUntil(this._destroyed$))
      .subscribe(queryParams => {
        this.returnUrl = queryParams.returnUrl;
      });
  }

  formIsValid(): boolean {
    return (this.email !== undefined && this.email.trim() !== '' && this.password !== undefined && this.password.trim() !== '');
  }

  onSubmit(): void {
    if (this.formIsValid()) {
      this.authService.login(this.email, this.password)
        .pipe(takeUntil(this._destroyed$), take(1))
        .subscribe(result => {
          if (result) {
            this.router.navigateByUrl(this.returnUrl).then();
          }
        });
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }
}
