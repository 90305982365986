import { Injectable } from '@angular/core';
import { SnackbarMessage } from '../interfaces/snackbar-message.interface';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private messages = new Subject<SnackbarMessage>();
  public messages$ = this.messages.pipe(debounceTime(0));

  addSnackbar(msg: string, msgType: string = 'success', timeoutMs = 3000): void {
    this.messages.next({
      message: msg,
      type: msgType,
      timeout: timeoutMs
    });
  }

}
