import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpSupportService } from '../../services/http-support.service';
import { HttpClient } from '@angular/common/http';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit, OnDestroy {

  inputType = 'password';

  private _destroyed$ = new Subject<void>();

  newPassword: string;
  setPasswordToken: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private httpSupport: HttpSupportService,
              private http: HttpClient,
              private snackbarService: SnackbarService) { }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(takeUntil(this._destroyed$))
      .subscribe(params => {
        this.setPasswordToken = params.token;
      });
  }

  formIsValid(): boolean {
    return this.newPassword !== undefined && this.newPassword.trim() !== ''
      && this.setPasswordToken !== undefined && this.setPasswordToken.trim() !== '';
  }

  onSubmit(): void {
    if (this.formIsValid()) {
      const actionUrl = this.httpSupport.getApiUrl('auth/setPassword');

      this.http.post<{ success: boolean }>(actionUrl, { token: this.setPasswordToken, password: this.newPassword })
        .pipe(takeUntil(this._destroyed$))
        .subscribe(result => {
          if (result.success) {
            this.snackbarService.addSnackbar('Nieuw wachtwoord opgeslagen', 'succes');
            this.router.navigate(['/auth']).then();

          }
          else {
            this.snackbarService.addSnackbar('FOUT: Wachtwoord niet opgeslagen', 'error');
          }
        });
    }

  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

}
