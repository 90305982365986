<form class="form-signin" #f="ngForm" (ngSubmit)="onSubmit()">
  <div class="text-center mb-4">
    <img class="mb-4" src="../../assets/images/logo.png" alt="" width="210" height="95">
  </div>

  <div class="mb-1">
    Voer je nieuwe wachtwoord in:
  </div>

  <div class="input-group mb-3">
    <input [type]="inputType" class="form-control" aria-label="Recipient's username" aria-describedby="button-addon2" name="newPassword" [(ngModel)]="newPassword">
    <div class="input-group-append">
      <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="inputType = (inputType == 'password' ? 'text' : 'password')"><i class="fa fa-eye"></i></button>
    </div>
  </div>


  <!--  <div class="checkbox mb-3">-->
  <!--    <label>-->
  <!--      <input type="checkbox" value="remember-me"> Remember me-->
  <!--    </label>-->
  <!--  </div>-->
  <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="formIsValid() == false">Opslaan</button>
</form>
