import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { BonInterface } from '../interfaces/bon.interface';
import { Observable } from 'rxjs';
import { ServiceTicketsService } from '../services/service-tickets.service';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'app-service-tickets',
  templateUrl: './service-tickets.component.html',
  styleUrls: ['./service-tickets.component.css']
})
export class ServiceTicketsComponent implements OnInit {

  public ticketList$: Observable<BonInterface[]>;

  constructor(public authService: AuthService,
              private ticketService: ServiceTicketsService,
              private navigationService: NavigationService) { }

  ngOnInit(): void {
    this.navigationService.setTitle('Servicebonnen');
    this.ticketList$ = this.ticketService.getListByUserId(this.authService.getUserId());
  }



}
