import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceTicketsComponent } from './service-tickets/service-tickets.component';
import { ServiceTicketDetailComponent } from './service-tickets/service-ticket-detail/service-ticket-detail.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TravelPointComponent } from './service-tickets/travel-point/travel-point.component';
import { AuthorizationComponent } from './authorization/authorization.component';
import { ForgotPasswordComponent } from './authorization/forgot-password/forgot-password.component';
import { SetPasswordComponent } from './authorization/set-password/set-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthService } from './services/auth.service';

export function appInitializer(authService: AuthService) {
  return () => new Promise((resolve) => {
    authService.refreshTokens().subscribe().add(resolve);
  });
}


@NgModule({
  declarations: [
    AppComponent,
    ServiceTicketsComponent,
    ServiceTicketDetailComponent,
    TravelPointComponent,
    AuthorizationComponent,
    ForgotPasswordComponent,
    SetPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    // ReactiveFormsModule,
    NgbModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
