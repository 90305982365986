import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ServiceTicketsComponent } from './service-tickets/service-tickets.component';
import { ServiceTicketDetailComponent } from './service-tickets/service-ticket-detail/service-ticket-detail.component';
import { AuthorizationComponent } from './authorization/authorization.component';
import { ForgotPasswordComponent } from './authorization/forgot-password/forgot-password.component';
import { SetPasswordComponent } from './authorization/set-password/set-password.component';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: 'service',
    canActivate: [AuthGuardService],
    children: [
      {
        path: ':ticketId',
        component: ServiceTicketDetailComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: '',
        component: ServiceTicketsComponent,
        canActivate: [AuthGuardService]
      }
    ]
  },
  {
    path: 'auth',
    children: [
      {
        path: 'forgot',
        component: ForgotPasswordComponent
      },
      {
        path: 'setpassword',
        component: SetPasswordComponent
      },
      {
        path: ':action',
        component: AuthorizationComponent
      },
      {
        path: '',
        component: AuthorizationComponent
      }
    ]
  },
  {
    path: 'setpassword',
    component: SetPasswordComponent
  },
  { path: '',   redirectTo: '/service', pathMatch: 'full' }, // redirect to `first-component`
  { path: '**', redirectTo: '/service', pathMatch: 'full' }  // Wildcard route for a 404 page
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
