import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HttpSupportService } from './http-support.service';
import { HttpClient } from '@angular/common/http';
import { SnackbarService } from './snackbar.service';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';
import { AccessTokenInterface } from '../interfaces/access-token.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private _destroyed$ = new Subject<void>();

  private _isLoggedIn$ = new BehaviorSubject(false);
  public IsLoggedIn$: Observable<boolean> = this._isLoggedIn$.pipe(debounceTime(0));

  private accessToken: string;
  private refreshToken: string;

  constructor(private httpSupport: HttpSupportService,
              private http: HttpClient,
              private snackbarService: SnackbarService) {
  }

  public getUserId(): number {
    const tokenData = this.getAccessToken().split('.');
    const tokenObj = JSON.parse(atob(tokenData[0]));
    const retVal = +tokenObj.userId;

    return retVal;
  }

  public getAccessToken(): string {
    if (this.accessToken === undefined || this.accessToken === null || this.accessToken.trim() === '') {
      this.accessToken = localStorage.getItem('braas_access_token');
    }

    return this.accessToken;
  }

  private setAccessToken(token: string): void {
    localStorage.setItem('braas_access_token', token);
    this.accessToken = token;
  }

  private getRefreshToken(): string {
    if (this.refreshToken === undefined || this.refreshToken === null || this.refreshToken.trim() === '') {
      this.refreshToken = localStorage.getItem('braas_refresh_token');
    }

    return this.refreshToken;
  }

  private setRefreshToken(token: string): void {
    localStorage.setItem('braas_refresh_token', token);
    this.refreshToken = token;
  }

  public login(username: string, password: string): Observable<boolean> {
    const actionUrl = this.httpSupport.getApiUrl('/auth/login');
    return this.http.post<{
      success: boolean,
      UserId: number,
      accessToken: string,
      refreshToken: string
    }>(actionUrl, { userId: username, pass: password })
      .pipe(
        takeUntil(this._destroyed$),
        take(1),
        map(loginResult => {
        if (loginResult.success) {
          this._isLoggedIn$.next(true);

          this.snackbarService.addSnackbar('Medewerker ' + loginResult.UserId + ' ingelogd', 'success');
          this.setAccessToken(loginResult.accessToken);
          this.setRefreshToken(loginResult.refreshToken);

          return true;
        }
        // Error is handled in interceptor
        // else {
        //   this.snackbarService.addSnackbar('Inloggen mislukt', 'error');
        // }

        return false;
      }));
  }

  public isAccessTokenValid(): boolean
  {
    const token = this.getAccessToken();
    if (token === null || token === undefined || token?.trim() === '') {
      return false;
    }

    const tokenParts = this.getAccessToken().split('.');
    if (tokenParts[0] == null) {
      return false;
    }

    const jsonStr = atob(tokenParts[0]);
    const data: AccessTokenInterface = JSON.parse(jsonStr);

    const dt = new Date(Date.parse(data.expUTC));
    const dtNow = new Date();

    if (dtNow < dt) {
      return true;
    }

    return false;
  }

  public refreshTokens(): Observable<boolean>
  {
    const refreshToken = this.getRefreshToken();
    if (refreshToken === null || refreshToken === undefined || refreshToken?.trim() === '') {
      return of (false);
    }

    const actionUrl = this.httpSupport.getApiUrl('/auth/refresh');

    return this.http.post<{
      success: boolean,
      UserId: number,
      accessToken: string,
      refreshToken: string
    }>(actionUrl, { refreshToken })
      .pipe(
        takeUntil(this._destroyed$),
        take(1),
        map(result => {
          if (result.success) {
            this.setAccessToken(result.accessToken);
            this.setRefreshToken(result.refreshToken);

            this._isLoggedIn$.next(true);
          }
          else {
            this._isLoggedIn$.next(false);
          }

          return result.success;
        })
      );
  }

  public logout(): void {
    localStorage.clear();
    this.accessToken = null;
    this.refreshToken = null;

    this._isLoggedIn$.next(false);
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

}
