
<div class="list-group">
  <ng-container *ngFor="let ticket of ticketList$|async; let last = last">
    <a [routerLink]="['/service', authService.getUserId() + '*' + ticket.SRCode + '*' + ticket.SRNumber]" class="list-group-item list-group-item-action">
      <h5 class="mb-1 description"><i class="fas fa-sticky-note pre-description-icon"></i> {{ ticket.StartDate }} - {{ ticket.Description }}</h5>
      <p class="mb-1">{{ ticket.Name }}</p>
      <p class="mb-1">{{ ticket.Street }}, {{ ticket.City }}</p>
    </a>
  </ng-container>
</div>
