import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpSupportService } from '../../services/http-support.service';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  email: string;

  _destroyed$ = new Subject<void>();

  constructor(private httpSupport: HttpSupportService,
              private http: HttpClient,
              private snackbarService: SnackbarService) { }

  ngOnInit(): void {
  }

  resetPassword(): void {
    const actionUrl = this.httpSupport.getApiUrl('/auth/forgotPassword');

    this.http.post<{ userId: string, email: string, 'e-mailSent': boolean }>(actionUrl, { userId: this.email })
      .pipe(takeUntil(this._destroyed$))
      .subscribe(res => {
        if (res['e-mailSent']) {
          this.snackbarService.addSnackbar('E-mail verzonden naar ' + res.email, 'success');
        }
        else {
          this.snackbarService.addSnackbar('Gebruiker niet gevonden', 'error');
        }
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

}
