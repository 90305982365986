import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TravelPointInterface } from '../../interfaces/travel-point.interface';
import { ReisAdresInterface } from '../../interfaces/reis-adres.interface';
import { GeolocationService } from '../../services/geolocation.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-travel-point',
  templateUrl: './travel-point.component.html',
  styleUrls: ['./travel-point.component.css']
})
export class TravelPointComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<void>();

  constructor(private geolocationService: GeolocationService,
              private snackbarService: SnackbarService) { }

  @Input() travelPointList: Array<TravelPointInterface> = [];
  @Input() addressList: Array<ReisAdresInterface> = [];
  @Input() enableTime = true;

  public selectedTravelPoint: TravelPointInterface;

  public loadingMessage = null;

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  setAddress(point: TravelPointInterface, address: ReisAdresInterface): void {
    if (point.TIJD === null && this.enableTime) {
      const today = new Date();
      point.TIJD = today.getHours().toString().padStart(2, '0') + ':' + today.getMinutes().toString().padStart(2, '0') + ':'
        + today.getSeconds().toString().padStart(2, '0');
    }

    if (address.ID === 'geo') {
      this.handleGeo(point);
    } else {
      point.STRAAT = address.STRAAT;
      point.PLAATS = address.PLAATS;
      point.GEO = address.GEO;
    }
  }

  handleGeo(point: TravelPointInterface): void {
    // Set loader
    this.loadingMessage = 'Locatie bepalen';

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        point.GEO = position.coords.latitude + ',' + position.coords.longitude;

        this.loadingMessage = 'Adres bepalen';

        this.geolocationService
          .getAddress(position.coords.latitude, position.coords.longitude)
          .pipe(
            take(1),
            takeUntil(this.destroyed$)
          )
          .subscribe(result => {
            point.STRAAT = result.street;
            point.PLAATS = result.city;

            this.addressList.unshift({
              ID: null,
              GEO: point.GEO,
              STRAAT: point.STRAAT,
              PLAATS: point.PLAATS
            });

            this.loadingMessage = null;
          });
      },
      error => {
        this.snackbarService.addSnackbar('Fout: kan locatie niet bepalen', 'error');
      });
    }
    else {
      this.loadingMessage = 'Locatie kan niet worden bepaald';
      this.snackbarService.addSnackbar('Fout: kan locatie niet bepalen', 'error');
    }
  }

  displayAddress(address: ReisAdresInterface): string {
    let retVal = address.STRAAT;
    if (address.PLAATS !== '' && address.PLAATS !== null) {
      retVal = retVal + ', ' + address.PLAATS;
    }

    return retVal;
  }

  addTravelPoint(): TravelPointInterface {
    let travelPoint: TravelPointInterface;
    if (this.enableTime) {
      travelPoint = {
        STRAAT: '',
        PLAATS: '',
        GEO: null,
        TIJD: null
      };
    }
    else {
      travelPoint = {
        STRAAT: '',
        PLAATS: '',
        GEO: null
      };
    }

    this.travelPointList.push(travelPoint);
    return travelPoint;
  }

  removeTravelPoint(idx: number): void {
    this.travelPointList.splice(idx, 1);
  }

}
