<form class="form-signin" (ngSubmit)="onSubmit()">
  <div class="text-center mb-4">
    <img class="mb-4" src="../../assets/images/logo.png" alt="" width="210" height="95">
  </div>

  <div class="form-label-group">
    <input type="email" id="inputEmail" name="inputEmail" [(ngModel)]="email" class="form-control" placeholder="Email of medewerker nr" required autofocus>
    <label for="inputEmail">Email of medewerker nr</label>
  </div>

  <div class="form-label-group">
    <input type="password" id="inputPassword" class="form-control" name="inputPassword" [(ngModel)]="password" placeholder="Password" required>
    <label for="inputPassword">Password</label>
  </div>

  <p><a routerLink="/auth/forgot">Wachtwoord vergeten?</a></p>

<!--  <div class="checkbox mb-3">-->
<!--    <label>-->
<!--      <input type="checkbox" value="remember-me"> Remember me-->
<!--    </label>-->
<!--  </div>-->
  <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="!formIsValid()">Login</button>
</form>
