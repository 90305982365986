
<div class="d-flex" id="wrapper" [ngClass]="{'toggled': showSideMenu}">

  <!-- Sidebar -->
  <div class="bg-light border-right" id="sidebar-wrapper" *ngIf="IsLoggedIn">
    <div class="sidebar-heading">Menu </div>
    <div class="list-group list-group-flush">
      <a routerLink="/service" class="list-group-item list-group-item-action bg-light" (click)="closeNav()">Servicebonnen</a>
      <a  class="list-group-item list-group-item-action bg-light" (click)="closeNav()">Uitleen</a>
      <a routerLink="/auth/logout" class="list-group-item list-group-item-action bg-light" (click)="closeNav()">Uitloggen</a>
    </div>
  </div>
  <!-- /#sidebar-wrapper -->

  <!-- Page Content -->
  <div id="page-content-wrapper">

    <nav class="navbar navbar-expand navbar-light bg-light border-bottom" *ngIf="IsLoggedIn">
      <a (click)="showSideMenu = !showSideMenu"><i class="fas fa-bars"></i></a>

      <div class="pageTitle">{{ title }}</div>
    </nav>


    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>

  </div>
  <!-- /#page-content-wrapper -->

</div>
<!-- /#wrapper -->


<div id="snackbar" [@snackbar] *ngIf="snackbarMsg !== null" class="align-middle" [ngClass]="[snackbarMsg.type]" [innerHTML]="snackbarMsg.message"></div>
