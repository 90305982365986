import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

export interface GeoLocationResult {
  ADRES: {
    STRAAT: string;
    PLAATS: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class GeolocationService
{

  constructor(private httpClient: HttpClient) { }

  getAddress(lat: number, long: number): Observable<{ street: string, city: string }> {
    return this.httpClient
      .get<GeoLocationResult>('https://basecone.braasallroundservice.nl:6443/devapi/geocode/' + lat + ',' + long)
      .pipe(
        map(result => {
          return {
            street: result.ADRES.STRAAT,
            city: result.ADRES.PLAATS,
          };
        })
      );

  }

}
