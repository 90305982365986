<form class="form-signin" #f="ngForm" (ngSubmit)="resetPassword()">
  <div class="text-center mb-4">
    <img class="mb-4" src="../../assets/images/logo.png" alt="" width="210" height="95">
  </div>

  <div class="form-label-group">
    <input name="email" [(ngModel)]="email" type="email" id="inputEmail" class="form-control" placeholder="Email of medewerker nr" required autofocus>
    <label for="inputEmail">Email of medewerker nr</label>
  </div>

  <!--  <div class="checkbox mb-3">-->
  <!--    <label>-->
  <!--      <input type="checkbox" value="remember-me"> Remember me-->
  <!--    </label>-->
  <!--  </div>-->
  <button class="btn btn-lg btn-primary btn-block mb-4" type="submit">Reset password</button>

  <div class="text-center mb-4">
    <a routerLink="/auth">Login</a>
  </div>
</form>
