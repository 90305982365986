<ng-container *ngIf="serviceTicket">

  <ng-container *ngFor="let travel of serviceTicket.Travel; let i = index">
    <div class="card" *ngIf="activeIndex == i">
      <div class="card-header d-inline-flex d-flex-row justify-content-between">
        <div class="dateNav">
          <button class="btn btn-primary" *ngIf="activeIndex > 0" (click)="setActiveIndex(activeIndex-1)"><i class="fas fa-chevron-left"></i></button>
        </div>
        <div class="vdl-title">{{ travel.DATUM }}</div>
        <div class="dateNav">
          <button class="btn btn-primary" *ngIf="activeIndex < (serviceTicket.Travel.length-1)" (click)="setActiveIndex(activeIndex+1)"><i class="fas fa-chevron-right"></i></button>
        </div>
      </div>
      <form #travelForm="ngForm" (ngSubmit)="onSubmit()">
        <div class="card-body">

            <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">

              <li [ngbNavItem]="1">
                <a ngbNavLink>Heen</a>

                <ng-template ngbNavContent>
                  <app-travel-point
                    [travelPointList]="travel.AANKOMST"
                    [addressList]="serviceTicket.REIS_ADRESSEN"
                  ></app-travel-point>
                </ng-template>
              </li>

              <li [ngbNavItem]="2">
                <a ngbNavLink>Project</a>


                <ng-template ngbNavContent>
                  <div class="mb-3">
                    {{ serviceTicket.SR_ADRES }}<br/>
                    {{ serviceTicket.SR_PLAATS }}
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-sm-2 col-lg-1 col-form-label">
                      <button type="button" class="btn btn-primary" (click)="travel.WERK_STARTTIJD = getTime()"><i class="fas fa-play-circle"></i></button>
                    </label>
                    <div class="col-8 col-sm-10 col-lg-11">
                      <input type="time" class="form-control" [(ngModel)]="travel.WERK_STARTTIJD" name="werkStartTijd" />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-sm-2 col-lg-1 col-form-label">
                      <button type="button" class="btn btn-primary" (click)="travel.WERK_EINDTIJD = getTime()"><i class="fas fa-stop-circle"></i></button>
                    </label>
                    <div class="col-8 col-sm-10 col-lg-11">
                      <input type="time" class="form-control" [(ngModel)]="travel.WERK_EINDTIJD" name="werkEindTijd">
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-8 offset-4 col-sm-10 offset-sm-2 col-lg-11 offset-lg-1">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridCheck1" [(ngModel)]="travel.WERK_ZONDER_PAUZE" name="werkZonderPauze">
                        <label class="form-check-label" for="gridCheck1">
                          Werk zonder pauze (30 minuten)
                        </label>
                      </div>
                    </div>
                  </div>



                  <div class="mt-5">
                    <h4>Boodschappen</h4>

                    <app-travel-point
                      [travelPointList]="travel.BOODSCHAP"
                      [addressList]="serviceTicket.REIS_ADRESSEN"
                      [enableTime]="false"
                    ></app-travel-point>

                  </div>


                </ng-template>
              </li>


              <li [ngbNavItem]="3">
                <a ngbNavLink>Terug</a>

                <ng-template ngbNavContent>
                  <app-travel-point
                    [travelPointList]="travel.VERTREK"
                    [addressList]="serviceTicket.REIS_ADRESSEN"
                  ></app-travel-point>
                </ng-template>
              </li>
            </ul>

            <div [ngbNavOutlet]="nav" class="mt-3"></div>



        </div>


        <div class="card-footer">
          <button type="submit" class="btn btn-success">Opslaan</button>
        </div>
      </form>

    </div>


  </ng-container>

</ng-container>
