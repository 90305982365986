import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { tap } from 'rxjs/operators';
import { SnackbarService } from '../services/snackbar.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.authService.getAccessToken();
    if (authToken !== null && authToken !== undefined && authToken.trim() !== '') {
      req = req.clone({
        headers: req.headers.set('X-Authorization', 'Bearer ' + authToken)
      });
    }

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if ( (event.body.success !== null && event.body.success === false) || (event.body.Success !== null && event.body.Success === false) ) {
              this.snackbarService.addSnackbar('Fout: ' + event.body.reason, 'error');
            }
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            this.snackbarService.addSnackbar('HTTP ERROR ' + err.status, 'error');
          }
        }
      )
    );

  }

  constructor(private authService: AuthService,
              private snackbarService: SnackbarService) {
  }

}
