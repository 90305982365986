
<div *ngIf="!selectedTravelPoint">
  <button type="button" class="btn btn-primary" (click)="selectedTravelPoint = addTravelPoint()" >Regel toevoegen</button>
</div>

<div *ngIf="selectedTravelPoint">
  <h3>Bewerken</h3>

  <ng-container *ngIf="selectedTravelPoint.STRAAT === '' && selectedTravelPoint.PLAATS === ''">
  <div class="form-group row">
    <label for="adres" class="col-sm-2 col-form-label">Adres</label>
    <div class="col-sm-10">
      <select id="adres" name="vanaf_locatie" class="form-control" (change)="setAddress(selectedTravelPoint, addressList[$event.target.selectedIndex-1])" *ngIf="loadingMessage === null">
        <option value="">-- KIES --</option>
        <option *ngFor="let address of addressList" [ngValue]="address">{{ displayAddress(address) }}</option>
      </select>

      <div *ngIf="loadingMessage !== null" class="ml-2">
        <i class="fas fa-spinner fa-spin"></i> {{ loadingMessage }}
      </div>
    </div>
  </div>
  </ng-container>

  <div class="form-group row" *ngIf="enableTime">
    <label for="tijd" class="col-sm-2 col-form-label">Tijd</label>
    <div class="col-sm-10">
      <input id="tijd" type="time" class="form-control" [(ngModel)]="selectedTravelPoint.TIJD" name="tijd">
    </div>
  </div>

  <ng-container *ngIf="selectedTravelPoint.STRAAT !== '' || selectedTravelPoint.PLAATS !== ''">
    <div class="form-group row">
      <label for="straat" class="col-sm-2 col-form-label">Straat</label>
      <div class="col-sm-10">
        <input id="straat" type="text" class="form-control" name="travelPointStreet" [(ngModel)]="selectedTravelPoint.STRAAT" />
      </div>
    </div>

    <div class="form-group row">
      <label for="plaats" class="col-sm-2 col-form-label">Plaats</label>
      <div class="col-sm-10">
        <input id="plaats" type="text" class="form-control" name="travelPointCity" [(ngModel)]="selectedTravelPoint.PLAATS" />
      </div>
    </div>
  </ng-container>

  <div class="form-group row">
    <div class="col-sm-10 offset-sm-2">
      <button id="okbtn" type="button" class="btn btn-primary" (click)="selectedTravelPoint = null">OK</button>
    </div>
  </div>

</div>


<table class="table mt-3" *ngIf="travelPointList.length > 0">
  <thead>
  <tr>
    <th scope="col" *ngIf="enableTime">Tijd</th>
    <th scope="col">Straat</th>
    <th scope="col">Plaats</th>
    <th scope="col" width="70"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let point of travelPointList; let t = index; let last = last;">
    <td *ngIf="enableTime">{{ point.TIJD }}</td>
    <td>{{ point.STRAAT }}</td>
    <td>{{ point.PLAATS }}</td>
    <td class="text-nowrap">
      <a class="fas fa-edit" (click)="selectedTravelPoint = point"></a>
      <a class="fas fa-times ml-2" (click)="removeTravelPoint(t)"></a>
    </td>
  </tr>
  </tbody>
</table>
