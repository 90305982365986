import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpSupportService {

  constructor() {}

  getApiUrl(path: string): string {
    if (environment.hasOwnProperty('apiBaseUrl')) {
      const apiBaseUrlSplitted = environment.apiBaseUrl.split('/');
      const apiUrl = this.getBase(apiBaseUrlSplitted);

      if (path.substr(0, 1) === '/') {
        // Add /api to beginning of the path if it's not there yet
        if (path.substr(0, this.getBasePath().length) !== this.getBasePath()) {
          path = this.getBasePath() + path;
        }
        return apiUrl + path;
      }

      apiBaseUrlSplitted.splice(0, 3);
      const firstPart = apiUrl + '/' + apiBaseUrlSplitted.join('/') + '/';

      if (firstPart === path.substr(0, firstPart.length)) {
        path = path.substr(firstPart.length);
      }

      return apiUrl + '/' + apiBaseUrlSplitted.join('/') + '/' + path;
    }

    return '';
  }


  getBase(batApiBaseUrlSplitted: Array<string>): string {
    let host = '';
    let port = environment.apiPort;

    if (batApiBaseUrlSplitted[2].search(':')) {
      const hostPort = batApiBaseUrlSplitted[2].split(':');
      host = hostPort[0].trim();
      port = parseInt(hostPort[1], 10);
    } else {
      host = batApiBaseUrlSplitted[2].trim();
    }

    if (host === '{host}') {
      host = window.location.hostname;
    }

    const apiUrl = batApiBaseUrlSplitted[0] + '//' + host + ':' + port;
    return apiUrl;
  }

  getBasePath(): string {
    return environment.apiBasePath;
  }

}
