import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { BonDetailInterface } from '../../interfaces/bon-detail.interface';
import { ServiceTicketsService } from '../../services/service-tickets.service';
import { TravelInterface } from '../../interfaces/travel.interface';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-service-ticket-detail',
  templateUrl: './service-ticket-detail.component.html',
  styleUrls: ['./service-ticket-detail.component.css']
})
export class ServiceTicketDetailComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  public serviceTicket: BonDetailInterface;

  public activeIndex: number;
  public activeTab = 1;

  constructor(private route: ActivatedRoute,
              private ticketService: ServiceTicketsService) { }

  ngOnInit(): void {
    this.route.params.pipe(
      takeUntil(this.destroyed$),
      switchMap((params: Params) => this.ticketService.getTravelById(params.ticketId))
    ).subscribe(item => {
      this.serviceTicket = item;
      this.activeIndex = item.Travel.length - 1;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  setActiveIndex(idx: number): void {
    this.activeIndex = idx;
  }

  onSubmit(): void {
    this.ticketService.updateTravel(this.serviceTicket)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(result => {

        console.log('Update result: ', result);
      });

  }

  getTime(): string {
    const today = new Date();
    return today.getHours().toString().padStart(2, '0') + ':' + today.getMinutes().toString().padStart(2, '0') + ':'
      + today.getSeconds().toString().padStart(2, '0');
  }

}
