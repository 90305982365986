import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private title = new BehaviorSubject<string>('');
  public title$ = this.title.asObservable();

  public setTitle(title: string): void {
    this.title.next(title);
  }
}
