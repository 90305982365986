import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from './services/navigation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SnackbarMessage } from './interfaces/snackbar-message.interface';
import { SnackbarService } from './services/snackbar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('snackbar', [
      state('in', style({
        transform: 'translateY(0)'
      })),
      transition('void => *', [
        style({
          transform: 'translateY(60px)'
        }),
        animate(500)
      ]),
      transition('* => void', [
        animate(300, style({
          transform: 'translateY(60px)'
        }))
      ])
    ])
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'bootstrap';

  _destroyed$ = new Subject<void>();

  IsLoggedIn = false;

  snackbarMsg: SnackbarMessage = null;
  snackbarTimeout: number;

  private destroyed$ = new Subject<void>();

  public showSideMenu = false;

  public constructor(private navigationService: NavigationService,
                     private authService: AuthService,
                     private snackbarService: SnackbarService) {
    this.navigationService.title$.pipe(takeUntil(this.destroyed$))
      .subscribe(title => {
        setTimeout(() => {
          this.title = title;
        });
      });


  }

  closeNav(): void {
    if (this.showSideMenu) {
      this.showSideMenu = false;
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  ngOnInit(): void {
    this.authService.IsLoggedIn$.pipe(takeUntil(this._destroyed$))
      .subscribe(LoggedIn => this.IsLoggedIn = LoggedIn);

    this.snackbarService.messages$
      .pipe(takeUntil(this._destroyed$))
      .subscribe((m: SnackbarMessage) => {
        this.snackbarMsg = m;
        if (this.snackbarTimeout > 0) {
          clearTimeout(this.snackbarTimeout);
        }

        this.snackbarTimeout = setTimeout(() => {
          this.snackbarMsg = null;
        }, this.snackbarMsg.timeout);
      });


  }

}
